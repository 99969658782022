export const useNotification = () => {
    const $q = useQuasar()

    function notify(type: 'positive' | 'negative' | 'info', msg: string) {
        $q.notify({
            type: type,
            message: msg
        })
    }

    return notify
}